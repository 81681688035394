import React, { useRef } from 'react'
import '../../../css/Style/Slider/Slider.css'
import Slider from 'react-slick';
import { useLocation, useNavigate } from 'react-router-dom';

const TripSlider = ({ trips, selectedMonth, similarTrips }) => {
    const navigate = useNavigate();
    const arrowRef = useRef();
    const location = useLocation();
    //Current Month
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });

    var settings = {
        dots: true,
        infinite: false,
        speed: 600,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4.2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    speed: 600,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1,
                    dots: false,
                    infinite: false,
                    speed: 600,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    swipeToSlide: true,
                    speed: 600,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    swipeToSlide: true,
                    speed: 600,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    className: "center",
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    infinite: false,
                    nextArrow: false,
                    prevArrow: false,
                    dots: false,
                    swipeToSlide: true,
                    speed: 1000,
                },
            }
        ]
    };

    function formatPrice(activeTrip) {
        let minPrice = activeTrip?.costing?.length ?
            activeTrip.costing.reduce((min, obj) => {
                return parseFloat(obj?.price) < parseFloat(min) ? parseFloat(obj?.price) : parseFloat(min);
            }, Infinity) : null;

        let formattedMinPrice = (minPrice !== null && minPrice !== Infinity) ? parseInt(minPrice, 10).toLocaleString("en-IN") : 'N/A';


        return formattedMinPrice
    }

    return (
        <div className="p-4 slider__trip">
            <Slider ref={arrowRef} {...settings}>
                {
                    trips?.map((trip) => (
                        <div key={trip.id} className="trips" onClick={() => navigate(`/${(trip.trip_url.toLowerCase()).replace(/\s+/g, '-')}`)}>
                            {<img src={trip.image} alt="" className="trip__image" />}
                            <div className='px-2'>
                                <div className='d-flex justify-content-between me-4 mb-1'>
                                    <p className='date__time p-0 mt-2 me-lg-10'><i className="fa-solid fa-location-dot me-1"></i>{trip.departure}</p>
                                    <p className='date__time p-0 mt-2 me-lg-5 me-4'><i className='fa-regular fa-clock me-1'></i>{trip.duration}</p>
                                </div>

                                <h5 className="m-0 trip__title">{trip.heading.toUpperCase()}</h5>

                                <span className='m-0 Mon_font text-secondary'><small>Starts at</small><h5 className='price ms-2 d-inline'>
                                    ₹{formatPrice(trip)}/-
                                </h5></span>

                                {
                                    
                                    similarTrips !== 'true' &&   <div className='dates Mon_font d-flex flex-wrap align-items-center'>
                                            <i className="fa-solid fa-calendar-days me-1"></i>
                                            {(trip.dates.filter(obj => {
                                                const dateMonth = new Date(obj.date).toLocaleString('default', { month: 'long' });
                                                if (selectedMonth !== null && selectedMonth !== "") {
                                                    return dateMonth === selectedMonth;
                                                } else {
                                                    return new Date(obj.date) >= new Date();
                                                }
                                            }))
                                            .map((item, index) => <div key={index} className='me-2'>
                                                    <span className='m-0'>
                                                        {new Date(item.date).toLocaleDateString('default', { month: 'long' }).slice(0, 3)} {new Date(item.date).toLocaleDateString('default', { day: 'numeric' })},
                                                    </span>
                                                </div>)
                                                .slice(0, 4)}
                                        </div>
                                }
                            </div>
                        </div>
                    ))
                }
            </Slider>
            {
                trips && trips?.length > 4 &&
                <div>
                    <button onClick={() => arrowRef.current.slickPrev()} className='arrow__left'><i className="fa-solid fa-chevron-left"></i></button>
                    <button onClick={() => arrowRef.current.slickNext()} className='arrow__right'><i className="fa-solid fa-chevron-right"></i></button>
                </div>
            }
        </div>
    )
}

export default TripSlider