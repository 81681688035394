import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../../../css/Style/SideMenu/style.css';
// import contactme from '../../images/5138237.png'
import axios from 'axios';
import {BASE_URL} from "../../pages/constant";
import api from "../../pages/api";
import {getAllCategoryData} from "../../util/GetAllCategory";
import {getHomeCategoryData} from "../../util/HomeCategory";

const SideMenu = ({ sideMenu, setSidemenu }) => {
    const navigate = useNavigate();
    const [allCategory, setAllCategory] = useState(false);
    const [homeBannerCategroy, setHomeBannerCategroy] = useState(null);

    // get all category
    const getAllCategory = async () => {
        const host = BASE_URL;
        await getAllCategoryData(BASE_URL).then((res) => {
            if (res) {
                setAllCategory(res)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getHomePageSliders = () => {
        const host = BASE_URL;
        getHomeCategoryData(BASE_URL).then((res) => {
            setHomeBannerCategroy(res[0]?.sideBarCategory)
        })
    }

    useEffect(() => {
        getAllCategory();
        getHomePageSliders();
    }, [])

    function getNextFourMonthsShortForm() {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // Get the current month (0-11)

        const nextFourMonths = [];

        for (let i = 0; i <= 3; i++) {
            const nextMonthIndex = (currentMonth + i) % 12; // Ensure the month index wraps around correctly
            nextFourMonths.push(monthNames[nextMonthIndex]);
        }

        return nextFourMonths;
    }

    const nextMonths = getNextFourMonthsShortForm();

    function getFullMonthName(shortForm) {
        const monthMap = {
            Jan: "January",
            Feb: "February",
            Mar: "March",
            Apr: "April",
            May: "May",
            Jun: "June",
            Jul: "July",
            Aug: "August",
            Sep: "September",
            Oct: "October",
            Nov: "November",
            Dec: "December"
        };

        // Convert the short form to Title Case to match the keys in the map
        let titleCaseShortForm = shortForm
        try {
            titleCaseShortForm = shortForm.charAt(0).toUpperCase() + shortForm.slice(1).toLowerCase();
        } catch (error) {
            return shortForm
        }

        return monthMap[titleCaseShortForm] || titleCaseShortForm
    }

    return (
        <div className={`${sideMenu ? "sideMenu sideMenu__open" : "sideMenu"} sticky-top top-sticky`}>
            <ul>
                {
                    homeBannerCategroy?.map((obj, index) => {
                        return <li key={obj}>
                            <Link to={`/category/${(obj.toLowerCase()).replace(/\s+/g, "-")}`}>{getFullMonthName(obj)}</Link>
                        </li>
                    })
                }

                {
                    nextMonths && nextMonths?.map((obj) => {
                        return <li key={obj}>
                            <Link to={`/${obj.toLowerCase().replace(/\s+/g, "-")}`}>{getFullMonthName(obj)}</Link>
                        </li>
                    }).slice(nextMonths.length - 4, nextMonths.length -2)
                }
                <li>
                    <Link onClick={() => setSidemenu(false)} to='/about'>About Us</Link>
                </li>
                <li>
                    <Link onClick={() => setSidemenu(false)} to='/contact'>Contact Us</Link>
                </li>
                {/*<li>*/}
                {/*    <Link onClick={() => setSidemenu(false)} to='/blog'>Blogs</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <Link onClick={() => setSidemenu(false)} to='/career'>Career</Link>*/}
                {/*</li>*/}
                <div className='text-center Mon_font'>
                    {/*<img src={contactme} alt="" />*/}
                    <button onClick={() => navigate("/contact")} className='btn btn-warning'>Talk to our expert</button>
                </div>
            </ul>
        </div>
    )
}

export default SideMenu
