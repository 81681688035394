import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import TalkToExpert from '../component/LandingPage/TalkToExpert';
import Testmonials from '../component/LandingPage/Testmonials';
import OtherLayout from '../component/Layout/OtherLayout';
import axios from 'axios';
// import noresult from '../images/noresult.png'
import '../../css/Style/CategoryListPage/Category.css'
import {BASE_URL} from "./constant";
import api from "./api";
import {getAllTrips} from "../util/FetchTrips";
import {getAllCategoryData} from "../util/GetAllCategory";

const CustomURLCategoryListPage = (defaultCategory) => {
    let defaultCategoryGiven = defaultCategory.category
    console.log("category: ", defaultCategoryGiven)
    let categoryBackground = defaultCategoryGiven
    const [trip, setTrip] = useState(null)

    const [category, setCategory] = useState(null)
    // const [categoryBackground, setCategoryBackground] = useState(null)
    const [month, setMonth] = useState(null)
    const [duration, setDuration] = useState(null)
    const [trending, setTrending] = useState(null)
    const params = useParams();
    const navigate = useNavigate();
    // const paramscategory = (params?.id.toUpperCase());
    document.title = `BookOfTrips - ${defaultCategoryGiven.name}`

    // get all category
    function getFullMonthName(shortForm) {
        const monthMap = {
            JAN: "JANUARY",
            FEB: "FEBRUARY",
            MAR: "MARCH",
            APR: "APRIL",
            MAY: "MAY",
            JUN: "JUNE",
            JUL: "JULY",
            AUG: "AUGUST",
            SEP: "SEPTEMBER",
            OCT: "OCTOBER",
            NOV: "NOVEMBER",
            DEC: "DECEMBER"
        };

        // Convert the short form to Title Case to match the keys in the map
        return monthMap[shortForm] || shortForm;
    }

    const pageHeading = defaultCategoryGiven.name
    console.log("pageHeading ", pageHeading)
    // console.log("paramscategory ", paramscategory)
    // const getAllCategory = async () => {
    //     const host = BASE_URL;
    //     await getAllCategoryData(BASE_URL).then((res) => {
    //         const image = res?.filter((category2) => {
    //
    //             console.log("category test ", category2)
    //             if ((category2.customUrl != null) && (category2.customUrl.toUpperCase() === paramscategory.toUpperCase())) {
    //                 console.log("final category ", category)
    //                 setCategory(category)
    //                 return category
    //             }
    //
    //         })
    //         // setCategoryBackground(image[0])
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }

    useEffect(() => {
        if ((categoryBackground != null) && categoryBackground.name.length > 0) {
            api(BASE_URL)
                .get(`book_of_trips/cityImages/get-all-city-image`)
                .then((res) => {
                    let arr = res.data.data;
                    const data = arr.find(item => item.city.toUpperCase() === categoryBackground?.name?.toUpperCase());
                    setTrending(data?.image)
                }).catch((err) => {
                console.log(err);
            });
        }

        // eslint-disable-next-line
    }, [])

    // useEffect(() => {
    //     if (paramscategory) {
    //         getAllCategory();
    //     }
    //     // eslint-disable-next-line
    // }, [paramscategory])

    // ==================== UNIQUE MONTH AND THEN FILTERING WITH STATE MONTH
    // const allDates = [];
    // const box = category?.map(obj => obj.dates.map(dateObj => allDates.push(dateObj)))
    // const uniqueMonths = Array.from(new Set(allDates.map(obj => new Date(obj.date).toLocaleString('default', { month: 'long' })))).sort((a, b) => new Date(Date.parse('01 ' + a + ' 2000')) - new Date(Date.parse('01 ' + b + ' 2000')));
    // const filterMonthData = category?.filter(obj => {
    //     return obj.dates.some(item => {
    //         const dateObj = new Date(item.date);
    //         return dateObj.toLocaleString('default', { month: 'long' }) === month;
    //     });
    // });
    // ==================== UNIQUE MONTH AND THEN FILTERING WITH STATE MONTH

    // ==================== FILTERING WITH STATE DURATION
    // const filterTrip = category?.filter(item => item.duration?.includes(duration))
    // ==================== FILTERING WITH STATE DURATION

    // get All trips
    const AllTrips = async () => {
        // const host = BASE_URL;
        await getAllTrips(BASE_URL).then((res) => {
            setTrip(res);
            setCategory(res?.filter(trip => {
                console.log("trip.category ", trip.category)
                console.log("category?.name ", category?.name)
                const isMatch = trip.category.some(tripCategory => tripCategory.toUpperCase() === defaultCategoryGiven?.name?.toUpperCase());
                return trip.location.toUpperCase()?.includes(category?.name?.toUpperCase()) || isMatch
            }));
            // console.log("category: ", category)
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        AllTrips();
        // eslint-disable-next-line
    }, [defaultCategoryGiven.name])

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [monthFilter, setMonthFilter] = useState('all');
    const [durationFilter, setDurationFilter] = useState('all');

    console.log("category ", category)
    const monthOptions = [...new Set(category?.flatMap((item) => item.dates.map((date) => date.date.slice(5, 7))))].map(
        (month) => <option value={month}>{monthNames[month - 1]}</option>
    );

    console.log("monthOptions ", monthOptions)

    // --------------- START ---- shorting the all trips duration ------------------
    const durationOptions = [...new Set(category?.map((item) => item.duration))].sort((a, b) => {
        const [nightsA, daysA] = a.split('N-');
        const [nightsB, daysB] = b.split('N-');

        if (parseInt(nightsA) !== parseInt(nightsB)) {
            return parseInt(nightsA) - parseInt(nightsB);
        } else {
            return parseInt(daysA) - parseInt(daysB);
        }
    }).map((duration) => (
        <option value={duration}>{duration}</option>
    ));
    // --------------- END ------------------


    function filterData() {
        return category?.filter(item => {
            if (monthFilter !== 'all') {
                // Check if any date in the item matches the selected month
                if (!item.dates.some(dateObj => new Date(dateObj.date).getMonth() === parseInt(monthFilter) - 1)) {
                    return false;
                }
            }
            if (durationFilter !== 'all' && item.duration !== durationFilter) {
                return false;
            }
            return true;
        });
    }

    const filteredData = filterData();

    function formatPrice(activeTrip) {
        let minPrice = activeTrip?.costing?.length ?
            activeTrip.costing.reduce((min, obj) => {
                return parseFloat(obj?.price) < parseFloat(min) ? parseFloat(obj?.price) : parseFloat(min);
            }, Infinity) : null;

        let formattedMinPrice = (minPrice !== null && minPrice !== Infinity) ? parseInt(minPrice, 10).toLocaleString("en-IN") : 'N/A';


        return formattedMinPrice
    }

    // const background = categoryBackground !== null ? categoryBackground.url :

    return (
        <>
            {
                <OtherLayout background={categoryBackground?.url || trending} title={pageHeading.charAt(0).toUpperCase() + pageHeading.slice(1)} subTitle={`BookOfTrips is a way for your happiness`}>
                    <div className="category__cont container-fluid px-lg-5 px-4 Mon_font my-5">
                        {
                            category?.length > 0 &&
                            <div>
                                <h2 className='text-start category__heading mb-4 fw-bold'>Our {categoryBackground?.name?.charAt(0)?.toUpperCase() + categoryBackground?.name?.slice(1)} packages</h2>{/* =============== MONTH FILTER ===============   */}
                                <select id="monthFilter" value={monthFilter} onChange={e => setMonthFilter(e.target.value)}>
                                    <option onClick={() => setMonth(null)} value='all'>Month</option>
                                    {monthOptions}
                                </select>

                                {/* =============== DURATION FILTER ===============   */}
                                <select id="durationFilter" value={durationFilter} onChange={e => setDurationFilter(e.target.value)}>
                                    <option onClick={() => setDuration(null)} value="all">Duration</option>

                                    {durationOptions}
                                </select>
                            </div>
                        }
                        <div className="row">
                            {
                                filteredData?.length === 0 ?
                                    <div className='text-center'>
                                        {/*<img src={noresult} alt="no-result" />*/}
                                        <h5 className='text-secondary text-center Mon_font'>No Trips Found!</h5>
                                    </div>
                                    :
                                    filteredData?.map((item) => {
                                        return (
                                            <>
                                                {
                                                    <div key={item.id} onClick={() => navigate(`/${(item?.trip_url.toLowerCase()).replace(/\s+/g, '-')}`)} className="text-center trip__container col-12 col-sm-12 col-lg-4 col-md-6 mx-lg-4">
                                                        <div className="trip__img__container" style={{ background: `url(${item.image}` }}></div>
                                                        <div className="trips text-start other__content">
                                                            <div className='d-flex justify-content-between'>
                                                                <p className='date__time p-0 mt-1'><i className="fa-solid fa-location-dot me-1"></i>{item.departure}</p>
                                                                <p className='date__time p-0 mt-1'><i className='fa-regular fa-clock me-1'></i>{item.duration}</p>
                                                            </div>
                                                            <h5 className="trip__title my-1">{item.heading}</h5>
                                                            <span className='m-0 text-secondary mt-1'><small>Starts at</small><h5 className='ms-2 d-inline'>
                                                                ₹{formatPrice(item)}/-
                                                            </h5></span>
                                                            <div className='dates Mon_font  mt-2 '>
                                                                <div className='d-flex flex-wrap justify-content-start align-items-center'>
                                                                    {/*{item.customised === 'false' && */}
                                                                    {1 === 1 &&
                                                                        <div className=''>
                                                                            <i className="fa-solid fa-calendar-days me-2"></i>
                                                                        </div>
                                                                    }
                                                                    {/*{item.customised === 'false' && (item.dates.filter(obj => {*/}
                                                                    {1 === 1 && (item.dates.filter(obj => {
                                                                        return new Date(obj.date) >= new Date()})).map(item =>

                                                                        <div className='me-2'>
                                                                            <p className='m-0'>
                                                                                {new Date(item.date).toLocaleDateString('default', { day: 'numeric', month: 'short' })},
                                                                            </p>
                                                                        </div>).slice(0, 10)
                                                                    }
                                                                </div>
                                                                {/*{item.customised === 'true' &&*/}
                                                                {1 === '1' &&
                                                                    <div className='m-0 me-1 mt-3'>
                                                                        <p className='m-0'>Customised Trip</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })
                            }
                        </div>
                    </div>
                    <TalkToExpert />
                    <div className='my-5'>
                        <Testmonials />
                    </div>
                </OtherLayout>
            }
        </>
    )
}

export default CustomURLCategoryListPage
