import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../css/Style/LandingPage/TripDetails.css';
import TripSlider from './TripSlider';

const TripDetails = ({ data, title, category, loading }) => {
    const navigate = useNavigate();
    const arrowRef = useRef();
    const [month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const allDates = [];
    const box = data?.map(obj => obj.dates.map(dateObj => allDates.push(dateObj)))
    const uniqueMonths = Array.from(new Set(allDates.map(obj => new Date(obj.date).toLocaleString('default', { month: 'long' })))).sort((a, b) => new Date(Date.parse('01 ' + a + ' 2000')) - new Date(Date.parse('01 ' + b + ' 2000')));

    if ((uniqueMonths.length > 0) && (!(uniqueMonths.includes(month)))) {
        setMonth(uniqueMonths[0])
    }

    const filteredData = data?.filter(obj => {
        return obj.dates.some(item => {
            const dateObj = new Date(item.date);
            return dateObj.toLocaleString('default', { month: 'long' }) === month;
        });
    });
    const trips = ((month === 'all' || !month) ? data : filteredData)

    return (
        <div className="container-fluid upcoming__trips px-lg-5 px-2">
            <div className='heading__date__button Mon_font'>
                <div className='d-flex justify-content-center align-items-center'>
                    <span className="category__heading ps-2">{title}</span>
                    <select className="custom-select" name="month" id="" onChange={(event) => { setMonth(event.target.value) }}>

                        {
                            uniqueMonths?.map((item) => {
                                return <option selected={item === month} value={item} >{item.slice(0, 3)}</option>
                            })
                        }
                    </select>
                    {/* <i className="fa-solid fa-chevron-down"></i> */}
                </div>
                <button onClick={() => navigate(`category/${category.replace(/\s+/g, '-')}`)} className='btn view_all'>View All</button>
            </div>

            <TripSlider trips={trips} selectedMonth={month} setMonth={setMonth}/>
        </div>
    )
}

export default TripDetails
