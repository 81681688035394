import React, {useEffect, useState} from 'react'
import '../../css/Style/LandingPage/LandingPage.css';
import TripDetails from '../component/TripDetails/TripDetails';
import Layout from '../component/Layout/HomeLayout';
import TalkToExpert from '../component/LandingPage/TalkToExpert';
import PromotionBanner from '../component/LandingPage/PromotionBanner';
import TrendingLocation from '../component/LandingPage/TrendingLocation';
import PopularDestination from '../component/LandingPage/PopularDestination';
import {BASE_URL} from './constant';
import api from './api';
import {getAllTrips} from '../util/FetchTrips';
import {getHomeCategoryData} from "../util/HomeCategory";


const LandingPage = () => {
    const [trip, setTrip] = useState(null)
    const [sliders, setSliders] = useState(null);
    const [formLoad, setFormLoad] = useState(false);
    const [homeBanner, setHomeBanner] = useState(false);
    let currentDate = new Date().toISOString().slice(0, 10);

    const getHomePageSliders = () => {
        const host = BASE_URL;
        getHomeCategoryData(BASE_URL).then((res) => {
            setSliders(res[0]?.top5HomeCategory)
        })
    }

    const getHomeBanner = () => {
        const host = BASE_URL;
        api(host)
            .get(`book_of_trips/get-home-banner`)
            .then((res) => {
                setHomeBanner(res.data?.data)
            })
    }


    // get All trips
    const AllTrips = async () => {
             await getAllTrips(BASE_URL).then((res) => {
                const currentTrips = res?.filter(trip => {
                        return trip.dates?.some(date => date?.date >= currentDate);
                    });

                    setTrip(currentTrips);  // Set trips from API response

                    // Cache the response data
                    // localStorage.setItem(cacheKey, JSON.stringify(tripsData));
                    // localStorage.setItem(`${cacheKey}_time`, Date.now());
                })
                .catch((err) => {
                    console.log(err);
                });
    }

    useEffect(() => {
        const isVisited = localStorage.getItem('isVisited');
        if (!isVisited) {
            setTimeout(() => {
                setFormLoad(true);
            }, 15000);
            localStorage.setItem('isVisited', 'true');
        }
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleBeforeUnload = () => {
        localStorage.removeItem('isVisited');
    };

    useEffect(() => {
        if (trip === null) {
            AllTrips();
        }
        getHomePageSliders();
        getHomeBanner();
    }, [])

    return (
        <Layout homeBanner={homeBanner} trip={trip} title="Escape the" subTitle="Create beautiful stories with Us..">

            {/* ============================== SLIDERS ============================== */}
            {
                sliders?.map((item, index) => {
                    const data = trip?.filter(trip => trip?.category?.includes((item)))
                    return data?.length > 0 && <div key={index} className="">
                        <TripDetails data={data} title={(item)} category={(item)} />
                    </div>
                }).slice(0, 1)
            }

            <PromotionBanner />

            {
                sliders?.map((item, index) => {
                    const data = trip?.filter(trip => trip.category?.includes((item)))
                    return data?.length > 0 && <div className="my-4">
                        <TripDetails data={data} title={(item)} category={(item)} />
                    </div>
                }).slice(1, 10)
            }
            <div className='p-lg-5 pt-lg-0 mb-4 '>
                <TrendingLocation />
            </div>

            <TalkToExpert />

            <PopularDestination />

            {/*<WhyTrustUS />*/}

            {/*<Testmonials />*/}

            {/*<OfferAndUpdates />*/}

            {/*<PopupForm />*/}

            {/*<DemoSlider />*/}

        </Layout>
    )
}

export default LandingPage