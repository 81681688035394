// import {BASE_URL} from "./constant";
import api from "../pages/api";


// Function to return the trips
export const getAllTrips = async (BASE_URL) => {
    const CACHE_DURATION = 10 * 60 * 1000;
    // const CACHE_DURATION = 0;
    const cacheKey = "allTripsData";
    const cachedTrips = localStorage.getItem(cacheKey);
    const cacheTime = localStorage.getItem(`${cacheKey}_time`);

    // Check if data exists in cache and is still valid
    if (cachedTrips && cacheTime && (Date.now() - cacheTime < CACHE_DURATION)) {
        return JSON.parse(cachedTrips);  // Return trips from cache
    } else {
        // Fetch new data if cache is not valid or doesn't exist
        try {
            const response = await api(BASE_URL).get(`book_of_trips/trips/get-all-trips`);
            const tripsData = response.data.data;

            // Cache the response data
            localStorage.setItem(cacheKey, JSON.stringify(tripsData));
            localStorage.setItem(`${cacheKey}_time`, Date.now());

            return tripsData;  // Return trips from API response
        } catch (err) {
            console.error("Error fetching trips:", err);
            throw err;  // Optionally, rethrow the error to handle it elsewhere
        }
    }
};

